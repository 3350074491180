angular
    .module('annexaApp')
    .component('annexaTypeaheadFieldsetProfiles',{
        templateUrl: './components/reg/annexa-typeahead-fieldset-profiles/annexa-typeahead-fieldset-profiles.html',
        controller: ['RestService', 'Language', '$rootScope', 'RegFactory', '$scope', function (RestService, Language, $rootScope, RegFactory, $scope) {
            var vm = this;

            vm.optionAdd = '';
            vm.contents = [];
            vm.selectedResponsible = undefined;
            
            var getSelectedDiligenceType = function() {
                var idSelected = -1;

                if(vm.formModel && vm.formModel.diligenceType) {
                    idSelected = vm.formModel.diligenceType;
                }

                return $linq(RegFactory.registerEntryDiligenceTypes).singleOrDefault(undefined, "x => x.id == " + idSelected);
            }

            vm.showFieldSet = function() {
                if(!$rootScope.app.configuration.register_init_dossier_diligence_type_multiple.value) {
                    if(!vm.formModel) {
                        return false;
                    } else if(!vm.formModel.diligenceType) {
                        return false;
                    }
                }

                return true;
            }

            vm.showNew = function () {
                var showNewAux = true;
                if(!$rootScope.app.configuration.register_init_dossier_diligence_type_multiple.value) {
                    if(vm.contents.length > 0) {
                        var selectedItem = getSelectedDiligenceType();

                        if(!selectedItem) {
                            showNewAux = false;
                        }else if(selectedItem.canStartDossier && vm.contents.length > 0) {
                            showNewAux = false;
                        }
                    }
                }

                if(vm.onlyOneProfile){
                    if(vm.contents.length > 0){
                        showNewAux = false;
                    }
                }

                return showNewAux;
            }

            vm.getTitle = function (item) {
                var title = '';

                if(item && item.object && item.object.userProfiles) {
                    _.forEach(item.object.userProfiles, function (value) {
                        title += value.user.name + ' ' + value.user.surename1 + (value.user.surename2 ? ' ' + value.user.surename2 + '\n' : '\n');
                    })
                }

                return title;
            }

            vm.getName = function (item) {
                var name = item.name;

                if(item.responsible) {
                    name += ' (' + item.responsible.name + ' ' + item.responsible.surename1 + (item.responsible.surename2 ? ' ' + item.responsible.surename2 : '') + ')';
                }

                return name;
            }

            vm.remove = function (index) {
                vm.contents.splice(index, 1);

                if(vm.formModel && vm.formModel.diligenceProfiles) {
                    vm.formModel.diligenceProfiles.splice(index, 1);
                }

                $rootScope.$broadcast('annexaTypeaheadFieldsetRemove');
            }

            vm.search = function (val) {
                if(!val || !val.val || (val.val != '*' && val.val.length < 3)) {
                    return [];
                }

                val.val = val.val == '*' ? '' : val.val;

                return RestService.filterData('common', 'Profile', {}, { search: val.val }, 0, 100, '', 3)
                    .then(function(data) {
                        var items = [];

                        if(data.data && data.data.content) {
                            _.forEach(JSOG.decode(data.data.content), function(item) {
                                if($linq(vm.contents).count("x => x.id == " + item.id) == 0) {
                                    if(vm.profilesOk && vm.profilesOk.length > 0){
                                    	if($linq(vm.profilesOk).count("x => x.id == "+item.id) > 0){
                                    		items.push({ id: item.id, value: item[Language.getActiveColumn()], object: item, responsible: undefined });
                                    	}
                                    }else{
                                    	items.push({ id: item.id, value: item[Language.getActiveColumn()], object: item, responsible: undefined });
                                    }
                                }
                            })
                        }

                        return items;
                    })
            }

            vm.addFunction = function () {
                if(vm.optionAdd) {
                    var itemSelected = { id: vm.optionAdd.id, name: vm.optionAdd.value, object: vm.optionAdd.object, deleted: false, responsible: vm.selectedResponsible };
                    vm.contents.push(itemSelected);
                    vm.optionAdd = '';
                    vm.selectedResponsible = undefined;


                    if(vm.formModel) {
                        if(!vm.formModel.diligenceProfiles) {
                            vm.formModel.diligenceProfiles = [];
                        }

                        vm.formModel.diligenceProfiles.push({ profile: itemSelected.object, responsible: itemSelected.responsible });
                    }

                    $rootScope.$broadcast('annexaTypeaheadFieldsetAdd');
                }
            }

            vm.showResponsibles = function() {
                var selectedItem = getSelectedDiligenceType();

                if (vm.optionAdd && vm.optionAdd.id && $rootScope.esetMode && (selectedItem.canStartDossier || selectedItem.canAppendDossier)) {
                    return true;
                }

                return false;
            }

            vm.showAdd = function () {
                var selectedDiligenceType = getSelectedDiligenceType();

                if(vm.optionAdd && vm.optionAdd.id) {
                    if(!selectedDiligenceType.canStartDossier && !selectedDiligenceType.canAppendDossier) {
                        return true;
                    }

                    if(!$rootScope.esetMode) {
                        return true;
                    }

                    if (vm.selectedResponsible) {
                        return true;
                    }
                }

                return false;
            }
        }],
        bindings: {
            label: '@',
            placeholder: '@',
            contents: '=',
            onlyOneProfile: '=',
            formModel: '=',
            profilesOk: '=?'
        }
    })
angular
    .module('annexaApp')
    .component('annexaBoxDiligences',{
        templateUrl: './components/reg/annexa-box-diligences/annexa-box-diligences.html',
        controller:['RegFactory', '$rootScope', 'AnnexaFormlyFactory', '$scope', 'GlobalDataFactory', function (RegFactory, $rootScope, AnnexaFormlyFactory, $scope, GlobalDataFactory) {
            var vm = this;

            this.$onInit = function () {
                vm.regInputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.input'").toArray();

                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.titleDiligencies';
                }

                if(!vm.new) {
                    vm.new = {};
                }

                vm.new.newFunc = function() {
                    var submitNewDiligence = function () {
                        var self = this;
                        this.form.$setSubmitted();

                        if(!this.model.diligenceProfiles || this.model.diligenceProfiles.length == 0) {
                            newModal.alerts.push({ msg: 'global.errors.profiles_required'});
                        } else if(this.form.$valid) {
                            var selectedDiligenceType = $linq(RegFactory.registerEntryDiligenceTypes).singleOrDefault(undefined, "x => x.id == " + this.model.diligenceType);

                            if(selectedDiligenceType) {
                                _.forEach(this.model.diligenceProfiles, function (item) {
                                    var newDiligence = {
                                        diligenceType: selectedDiligenceType,
                                        diligenceProfile: item.profile,
                                        responsibleUser: item.responsible,
                                        diligenceState: selectedDiligenceType.canStartDossier || selectedDiligenceType.canAppendDossier ? 'PENDING_ACCEPT' : 'PENDING_RECEPT',
                                        active: true,
                                        registerEntryDiligenceComments: self.model.comment ? [{ comments: self.model.comment }] : []
                                    };


                                    if((vm.search && (!vm.search.saveSearch || (vm.search.saveSearch && !vm.search.addAfter))) || !vm.search) {
                                        vm.content.push(newDiligence);
                                    }

                                    if(vm.search && vm.search.saveSearch) {
                                        vm.search.saveSearch(newDiligence);
                                    }
                                });

                                $rootScope.$broadcast('closeAnnexaFormModal', { id: 'modalNewDiligence' });
                            } else {
                                newModal.alerts.push({ msg: 'global.errors.diligenceTypeNotFound'});
                            }
                        }
                    };
                    var profilesRegisterOffice = [];
                    if(vm.registerEntry && vm.registerEntry.registerEntryOffice &&vm.registerEntry.registerEntryOffice.profiles){
                    	profilesRegisterOffice = $linq(vm.registerEntry.registerEntryOffice.profiles).select("x => x.profile").toArray();
                    }
                    var newModal = RegFactory.newDiligenceModal(vm.content, submitNewDiligence, undefined, undefined, undefined, profilesRegisterOffice);

                    AnnexaFormlyFactory.showAnnexaFormModal('modalNewDiligence', newModal);
                };

                $scope.$on('registerEntryDiligenceSendedTo', function (event, args) {
                    var indexPreviousDiligence = $linq(vm.content).indexOf("x => x.id == " + args.previousDiligence.id);

                    if(indexPreviousDiligence != -1) {
                        vm.content[indexPreviousDiligence] = args.newDiligence;
                    }
                });
            };
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            config: '=',
            origin: '@',
            registerEntry: '=?'
        }
    })
    .component('annexaBoxDiligencesRender',{
        templateUrl: './components/reg/annexa-box-diligences/annexa-box-diligences-render.html',
        require: {
            registerEntryComponent: '^^annexaBoxObjectReg'
        },
        controller:['Language', '$state', 'DialogsFactory', 'AnnexaPermissionsFactory', '$filter', 'RegFactory', '$rootScope', function (Language, $state, DialogsFactory, AnnexaPermissionsFactory, $filter, RegFactory, $rootScope) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            vm.isDiligenceWindow = function() {
                return $state.current.name == 'annexa.reg.input.diligences.edit';
            };

            vm.getAccordionHeadingText = function (item) {
                var retText = item.diligenceProfile[vm.languageColumn];

                if(item.responsibleUser) {
                    retText += ' (' + item.responsibleUser.name + ' ' + item.responsibleUser.surename1;

                    if(item.responsibleUser.surename2) {
                        retText += ' ' + item.responsibleUser.surename2;
                    }

                    retText += ')';
                }

                return retText;
            };

            vm.delete = function (id) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteDiligence')
                    .then(function (data) {
                    	var indexDiligence = $linq(vm.content).indexOf("x => x.id == " + id);
                        var removedId = vm.content[indexDiligence].id;

                        vm.content.splice(indexDiligence, 1);

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxDiligencesDelete', { removedId: removedId, origin: vm.origin});
                        }
                    }).catch(function(data) {
                        //Empty
                });
            };

            vm.showSendTo = function(item) {
            	if ($rootScope.app.configuration.allow_diligence_send_to.value) {
	                var acceptableStates = ['REJECT'];
	                acceptableStates.push('PENDING_ACCEPT');
	                acceptableStates.push('ACCEPT');
	
	                return (item.diligenceType.canStartDossier || item.diligenceType.canAppendDossier) &&
	                    _.contains(acceptableStates, item.diligenceState) && 
	                    item.diligenceType.type == 'ACCEPTANCE' &&
	                    //item.registerEntryDiligenceHistorical.regEntryDiligenceInit == null && //eset
	                    !vm.isDiligenceWindow();
            	} else {
            		return false;
            	}
            };

            vm.showActions = function(item) {
                if (AnnexaPermissionsFactory.haveProfile(item.diligenceProfile) && vm.isEdit) {
                	return true;
                }
                
                if (item.diligenceType.type == 'ACCEPTANCE' && item.diligenceState == 'PENDING_ACCEPT' && vm.isEdit) {                        	                        	
                	if (item.registerEntryDiligenceHistorical.regEntryDiligenceInit && 
                			item.registerEntryDiligenceHistorical.regEntryDiligenceInit.diligenceProfile &&
                			AnnexaPermissionsFactory.haveProfile(item.registerEntryDiligenceHistorical.regEntryDiligenceInit.diligenceProfile)) {
                		//Tiene el perfil creador
                		return true;
                	}
                	if (item.registerEntryDiligenceHistorical.regEntryDiligenceEnded && 
                			item.registerEntryDiligenceHistorical.regEntryDiligenceEnded.diligenceProfile &&
                			AnnexaPermissionsFactory.haveProfile(item.registerEntryDiligenceHistorical.regEntryDiligenceEnded.diligenceProfile)) {
                		//Tiene el perfil asignado a la diligencia
                		return true;
                	}
                }
            };

            vm.showDelete = function(item) {
                if(item.diligenceState != 'ACCEPT' && item.diligenceState != 'FINISHED'){
                    if(!vm.isEdit) {
                        return true;
                    }

                    if(item.diligenceState == 'PENDING_RECEPT') {
                        return true;
                    }

                    if(item.registerEntryDiligenceHistorical) {
                        if(!item.registerEntryDiligenceHistorical.regEntryDiligenceInit && item.diligenceType.type != 'ACCESS') {

                            return true;
                        }
                        
                        if (item.diligenceType.type == 'ACCEPTANCE' && item.diligenceState == 'PENDING_ACCEPT') {                        	                        	
                        	if (item.registerEntryDiligenceHistorical.regEntryDiligenceInit && 
                        			item.registerEntryDiligenceHistorical.regEntryDiligenceInit.diligenceProfile &&
                        			AnnexaPermissionsFactory.haveProfile(item.registerEntryDiligenceHistorical.regEntryDiligenceInit.diligenceProfile)) {
                        		//Tiene el perfil creador
                        		return true;
                        	}
                        }
                    }
                }
                return false;
            };

            vm.getComments = function (item) {
                if(!item.registerEntryDiligenceComments || item.registerEntryDiligenceComments.length == 0) {
                    return $filter('translate')('global.literals.empty_comments');
                }

                var comments = '';

                var diligenceComments = $linq(item.registerEntryDiligenceComments).orderBy("x => x.createdDate").toArray();

                _.forEach(diligenceComments, function(value) {
                    if(value.createdUser) {
                        comments += value.createdUser.name + ' ' + value.createdUser.surename1 + (value.createdUser.surename2 ? ' ' + value.createdUser.surename2 : '');
                        comments += ' - ';
                    }

                    comments += value.comments + '\n';
                });

                return comments;
            };

            vm.getCommentsIcon = function(item) {
                if(!item.registerEntryDiligenceComments || item.registerEntryDiligenceComments.length == 0) {
                    return 'chat_bubble';
                }

                return 'speaker_notes';
            };

            vm.createComment = function(item) {
                RegFactory.createRegisterEntryDiligenceCommentModal(item);
            };

            vm.sendToDiligence = function(item) {
                RegFactory.sendTodiligenceModalOpen(item, vm.registerEntryComponent.registerEntry);
            };
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    });
/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaRegisterDocument', {
        templateUrl: './components/reg/annexa-register-document/annexa-register-document.html',
        controller: ['Language', '$scope', function (Language, $scope) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            this.$onInit = function () {
                var getButtonModel = function (button) {
                    var model = undefined;

                    if(button) {
                        model = {};
                        model[button.id] = undefined;

                        _.forEach(button.params, function (val, index) {
                            if (val.frontendType == 'INPUT' || val.frontendType == 'TEXTAREA') {
                                button.params[index].optionsUrl = undefined;
                                if(!model[button.id]) {
                                    model[button.id] = {};
                                }

                                model[button.id][val.id] = undefined;
                            }
                        })
                    }

                    return model;
                }

                $scope.$on('annexaCustomFieldComponentModelChanged', function (event, args) {
                    if(args.plugin){
                        if(!vm.model[args.plugin]){
                            vm.model[args.plugin] = {};
                        }
                        vm.model[args.plugin][args.key] = args.model;
                    }

                });

                vm.model = getButtonModel(vm.plugin);
            }
        }],
        bindings: {
            plugin: '=',
            submitFunction: '=',
            model: '='
        }
    })